<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(onSubmit)"
      @close="isOpen = false, $emit('close')"
      :loading="isLoading"
      submit="Save"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <BackButton @onClick="isOpen = false, $emit('close')" variant="secondary" />
          <p class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            Add Timelines
          </p>
        </div>
      </template>
      <template v-if="(!timelines.length)">
        <div class="flex flex-col justify-center items-center gap-5 px-10 py-20">
          <Icon
            icon-name="paygrade_Illustration"
            size=""
            class-name="w-32 h-32"
          />
          <p class="font-normal text-base leading-5 text-jet text-center">
            There are no timelines for this review presently. Click "Add Timelines" to start adding new timelines
          </p>
          <div class="hover:bg-ghostWhite px-5 py-2 rounded-md cursor-pointer">
            <div @click="isOpen=false; $emit('click-create')"
              class="flex justify-start items-center gap-2">
              <Icon icon-name="icon-plus" size="xs" />
              <span class="font-semibold text-base text-flame leading-5">
                Add Timelines
              </span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col w-full justify-start gap-2">
          <div v-for="(timeline, index) in timelines" :key="`${timeline}-${index}`">
            <div class="flex flex-col justify-start items-start gap-2 leading-5 w-full">
              <div class="w-full flex flex-col justify-start items-start gap-3">
                <div class="flex justify-between items-center w-full gap-2">
                  <TextInput
                    v-if="timeline.active"
                    v-model="timeline.type"
                    :rules="['required']"
                    placeholder="Enter"
                    class="w-full"
                    min="1"
                  />
                  <p class="font-semibold text-base text-darkPurple" v-else>
                    {{ timeline.type }}
                  </p>
                  <template v-if="timeline.type !== 'Management Approvers'">
                    <div @click="!timeline.active ? $set(timeline, 'active', true) : $delete(timeline, 'active')">
                      <Icon icon-name="save" size="xs" class-name="text-flame cursor-pointer" v-if="timeline.active" />
                      <Icon icon-name="edit" size="xs" class-name="text-blueCrayola cursor-pointer" v-else />
                    </div>
                  </template>
                </div>
                <div class="flex justify-between items-end gap-8 w-full">
                  <div class="w-full text-darkPurple flex flex-col justify-start gap-2"
                    :class="(Object.keys(timeline.period[0]).length > 1) ? 'w-4/5' : 'w-full'">
                    <p class="font-normal text-sm">Date Period</p>
                    <div class="border border-romanSilver rounded-md pl-2 w-full h-full">
                      <div class="flex justify-start items-center gap-2 w-full h-10">
                        <div class="flex justify-center border-r border-romanSilver h-6 pr-2">
                          <Icon icon-name="calendar" size="xs" />
                        </div>
                        <div class="h-10 mt-1 w-full">
                          <date-range-picker
                            :range="true"
                            :editable="false"
                            v-model="dateRange[index]"
                            placeholder="Select Date"
                            @input="getDateRange($event, index)"
                            :rules="['required']"
                            v-if="(Object.keys(timeline.period[0]).length > 1)"
                          />
                          <date-range-picker
                            v-else
                            :range="false"
                            :editable="false"
                            v-model="dateRange[index]"
                            placeholder="Select Date"
                            @input="getDateRange($event, index)"
                            :rules="['required']"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-if="timeline.type !== 'Management Approvers'">
                    <div class="flex justify-end w-2/5" v-if="(Object.keys(timeline.period[0]).length > 1)" @click="participants = timeline.participants">
                      <div class="w-full" @click="!timeline.isOpen ? $set(timeline, 'isOpen', true) : $delete(timeline, 'isOpen')">
                        <div class="flex justify-end items-center gap-2 cursor-pointer w-full">
                          <Icon icon-name="icon-plus" class-name="text-flame" size="xs" />
                          <p class="font-semibold text-base leading-5 text-flame whitespace-nowrap">
                            Add Participant
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="flex justify-start items-start flex-wrap gap-2 my-1">
                <div v-for="participant in timeline.participants" :key="participant.id">
                  <div class="bg-ghostWhite rounded-md px-2">
                    <div class="flex justify-between items-center gap-2">
                      <p class="text-jet text-base font-normal">
                        {{ participant.fname }} {{ participant.lname }}
                      </p>
                      <template v-if="timeline.type !== 'Management Approvers'">
                        <div @click="timeline.participants = timeline.participants.filter(i => i.id !== participant.id)">
                          <Icon icon-name="close-icon" class="text-jet cursor-pointer" size="xxs" />
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <Divider class="w-full" border-dash />
              <template v-if="timeline.isOpen">
                <div class="overlayer flex flex-wrap justify-center items-center">
                  <Card class="bg-white relative p-4 w-4/12">
                    <div class="flex flex-col justify-start items-start gap-2">
                      <h3 class="font-bold text-lg leading-5 text-darkPurple">
                        Add Participant
                      </h3>
                      <RadioButton
                        space-between="mr-2 ml-3 text-jet text-sm font-normal leading-5"
                        v-model="parameters"
                        default="designation"
                        :options="[
                          { name: 'By Designation', value: 'designation' },
                          { name: 'By Job Level', value: 'level'},
                        ]"
                      />
                      <div class="flex flex-col justify-start items-start gap-5 w-full">
                        <div class="w-full" v-if="parameters === 'designation'">
                          <p class="font-normal text-sm leading-5 text-jet">
                            Designation
                          </p>
                          <Select
                            placeholder="--Select--"
                            @input="onSearchEmployees({ designation: $event })"
                            :options="designationOptions"
                            :rules="['required']"
                          />
                        </div>
                        <div class="w-full" v-if="parameters === 'level'">
                          <p class="font-normal text-sm leading-5 text-jet">
                            Level
                          </p>
                          <Select
                            placeholder="--Select--"
                            @input="onSearchEmployees({ level: $event })"
                            :options="levelOptions"
                            :rules="['required']"
                          />
                        </div>
                      </div>
                      <div class="w-full flex flex-col justify-start gap-2">
                        <p class="text-sm text-jet">Participant</p>
                        <v-select
                          :clearable="false"
                          :filterable="false"
                          class="style-select"
                          :options="employeeOptions"
                          @input="onSelectedParticipant($event)"
                          @search="onSearchEmployees({search: $event, items: 100 })"
                        >
                          <template #option="{fname, lname, orgFunction, userDesignation, photo }">
                            <div class="flex flex-row justify-start gap-2">
                              <template>
                                <img v-if="photo" class="w-8 h-8 rounded-md" :src="photo" alt="profile pic" />
                                <div v-else class="w-8 h-8 px-2 py-2 rounded-md border flex justify-center items-center">
                                  <span class="text-blueCrayola text-xs font-semibold text-center">
                                    {{ $getInitials(`${fname} ${lname}`) }}
                                  </span>
                                </div>
                              </template>
                              <div class="hover:text-white flex flex-col justify-start">
                                <p class="font-bold text-sm cursor-pointer">
                                  {{ fname }} {{ lname }}
                                </p>
                                <div class="flex flex-row justify-start items-center gap-2" style="font-size:10px">
                                  <span class="uppercase">{{ orgFunction ? orgFunction.name : '' }}</span>
                                  <span class="capitalize">{{ userDesignation ? `${userDesignation.name}` : '' }}</span>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template #selected-option="{fname, lname}">
                            <div>
                              <p class="">{{ fname }} {{ lname }}</p>
                            </div>
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <Icon style="margin-right:14px" icon-name="caret-down" size="xsm" />
                            </span>
                          </template>
                          <template #list-footer>
                            <li class="text-center text-xs">
                              Type name of employee to search more
                            </li>
                          </template>
                        </v-select>
                      </div>
                      <div class="flex justify-start items-start flex-wrap gap-2 my-3">
                        <div v-for="participant in participants" :key="participant.id">
                          <div class="bg-ghostWhite rounded-md px-2">
                            <div class="flex justify-between items-center gap-2">
                              <p class="text-jet text-base font-normal">
                                {{ participant.fname }} {{ participant.lname }}
                              </p>
                              <div @click="participants = participants.filter(i => i.id !== participant.id)">
                                <Icon icon-name="close-icon" class="text-jet cursor-pointer" size="xxs" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex justify-end w-full gap-2">
                        <Button
                          label="Cancel" variant="secondary btn-border" style="padding:0 20px"
                          @onClick="timeline.isOpen = false"
                        />
                        <Button
                          label="Save" variant="primary btn-bg" style="padding:10px 30px"
                          @onClick="
                            timeline.isOpen = false;
                            timeline.participants = JSON.parse(JSON.stringify(participants))
                          "
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
  import * as _ from 'lodash'
  import { format } from "date-fns"
  import VSelect from "vue-select"
  import "vue-select/dist/vue-select.css"
  import DateRangePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import { ValidationObserver } from "vee-validate"
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
  import Card from "@scelloo/cloudenly-ui/src/components/card"
  import Button from "@scelloo/cloudenly-ui/src/components/button"
  import Select from "@scelloo/cloudenly-ui/src/components/select"
  import RadioButton from "@scelloo/cloudenly-ui/src/components/radio"
  import TextInput from "@scelloo/cloudenly-ui/src/components/text"
  import RightSideBar from "@/components/RightSideBar"
  import Icon from "@/components/Icon"
  import Divider from "@/components/divider"

  export default {
    name: 'CreateReviewTimelines',
    components: {
      ValidationObserver,
      VSelect,
      DateRangePicker,
      BackButton,
      Button,
      RadioButton,
      Select,
      Card,
      TextInput,
      RightSideBar,
      Icon,
      Divider
    },
    data(){
      return {
        dateRange: [],
        isLoading: true,
        isOpen: false,
        activeIndex: '',
        parameters: '',
        employees: [],
        selectedItems: [],
        employeeOptions: [],
        participants: [],
        disabledSelected: false,
        isOpenParticipants: false,
        designationOptions: [],
        levelOptions: [],
        compensationWorkflow: {},
        timelines: [
          { type: "Compensation Planning, merit based allocation weights.", period: [{startDate: '', endDate: ''}], participants: [], },
          { type: "Functional Reviews & Adjustment recommendations", period: [{startDate: '', endDate: ''}], participants: [], },
          { type: "Management Approvers", period: [{startDate: '', endDate: ''}], participants: [], },
          { type: "Effective Date for Merit Increase", period: [{startDate: ''}], },
          { type: "Effective Date for Lump Sum Award", period: [{startDate: ''}], },
          { type: "Effective Date for Promotion", period: [{startDate: ''}], },
          { type: "Notification Date", period: [{startDate: ''}], },
        ]
      }
    },
    methods: {
      async toggle(payload){
        this.isOpen = true
        if(_.size(payload.timelines)){
          await this.getReviewTimelines(payload.reviewId)
        }
        this.isLoading = false
      },
      async getReviewTimelines(reviewId){
        await this.$_getReviewTimelines(reviewId).then(async({ data }) => {
          this.timelines = await data.data.map((timeline, i) => {
            if(!_.gt(_.size(_.keys(_.first(timeline.period))), 1)){
              this.dateRange[i] = [new Date(_.first(timeline.period)?.startDate)]
            }
            this.dateRange[i] = [
              new Date(_.first(timeline.period)?.startDate),
              new Date(_.first(timeline.period)?.endDate)
            ]
            return timeline
          })
        })
      },
      getDateRange($event, activeIndex){
        if(_.isArray($event)){
          this.timelines[activeIndex].period = [{
            startDate: format(new Date($event[0]), "yyyy-MM-dd"),
            endDate: format(new Date($event[1]), "yyyy-MM-dd"),
          }]
        } else {
          this.timelines[activeIndex].period = [{
            startDate: format(new Date($event), "yyyy-MM-dd")
          }]
        }
      },
      onSelectedParticipant(payload){
        this.participants = [...new Set([...this.participants, payload])]
      },
      onSubmit(){
        const timelines = this.timelines?.map(({ type, period, participants }) => ({
          type, period, participants: participants?.map(i => i.userId)
        }))
        this.$emit('submit', timelines)
        this.isOpen = false
      },
      async onSearchEmployees({ search = '', items = 5, level, designation }) {
        /* eslint-disable no-param-reassign */
        if(this.parameters === 'designation'){
          search = `${search}&designationId=${designation}`
        } else if(this.parameters === 'level'){
          search = `${search}&levelId=${level}`
        }
        clearTimeout(this.timeout)
        if (!search) {
          const queryString = `&perPage=${items}`
          await this.$_getAllEmployeesWithQueries(queryString) .then(({ data }) => {
            this.employeeOptions = data.data.employees?.map(item => ({
              ...item, function: item.orgFunction?.name,
              designation: item.userDesignation?.name
            }))
          })
        }
        this.timeout = setTimeout(async () => {
          const queryString = `&search=${search}&perPage=${items}`
          await this.$_getAllEmployeesWithQueries(queryString).then(({ data }) => {
            this.employeeOptions = data.data.employees?.map(item => ({
              ...item, function: item.orgFunction?.name,
              designation: item.userDesignation?.name
            }))
          })
        }, 500)
      },
      async getDesignation() {
        await this.$_getDesignations('').then(({ data }) => {
          this.designationOptions = data.designations.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getLevels() {
        await this.$_getLevels().then(({ data }) => {
          this.levelOptions = data.levels.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getApprovalFlowApprovers(){
        const workflow = await this.$_getApproval().then(({ data }) => (
          data.workFlow.find(item => item.slug === 'compensationProposal')
        ))
        if(Object.keys(workflow).length){
          await this.$_getApprovalFlowApprovers(workflow.id).then(({ data }) => {
            const findIndex = this.timelines.findIndex(t => t.type ==='Management Approvers')
            const compensationWorkflow = data[0].domains[0].approvers
            const workflowApprovers = compensationWorkflow.map(f => f.employee)
            this.timelines[findIndex].participants= workflowApprovers
          })
        }
      }
    },
    async created(){
      this.getApprovalFlowApprovers()
      await this.getDesignation()
      await this.getLevels()
      await this.onSearchEmployees({})
    },
  }
</script>

<style lang="scss">
  .rightSidebar{
    width: 520px !important;
  }
  .backdrop_layer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 5;
  }
  .daterange-picker {
    position: absolute !important;
    z-index: 10000;
    .calendar{
      display: none;
    }
    .inputs-container {
      border: 0 !important;
    }
    .divider {
      padding: 0 !important;
    }
    input {
      font-size: 13px !important;
      width: 90px;
    }
  }
  .mx-datepicker, .mx-datepicker-range {
    width: 100% !important;
  }
  .mx-input {
    box-shadow: none !important;
    border: none !important;
    width: 95% !important;
  }
  .mx-icon-calendar {
    display: none !important;
  }
  .timeline-class {
    display: none!important;
  }
  .btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>

